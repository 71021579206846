<script>
import store from "@/store";

export default {
  name: "Login",

  data: () => ({
    model: {
      email: "root@domain.com",
      password: "123456789",
      user_type: "patient",
    },
    errorMessage: "",
    showPassword: false,
    forgot: false,
  }),

  computed: {
    PasswordType() {
      if (this.showPassword) {
        return "text";
      }
      return "password";
    },
    rules() {
      return {
        email: [
          (v) =>
            !v || /.+@.+\..+/.test(v) || this.$t("global_validation.email"),
        ],
        password: [(v) => !!v.length || this.$t("auth.validation.required")],
      };
    },
  },

  methods: {
    async signIn() {
      try {
        if (await this.$refs.form.validate()) {
          const response = await this.$API.auth().login(this.model);

          await this.$store.dispatch("authentication/login", response);
          if (response.user.position === "patient") {
            await this.$router.push("/profile/tab/history");
          }

          if (response.user.position === "doctor") {
            await this.$router.push("/profile/tab/patients");
          }
        }
      } catch (e) {
        this.errorMessage = e.message;
      }
    },
    async forgotPassword() {
      try {
        console.log("recovery");
      } catch {
        this.errorMessage = this.$t("auth.validation.required");
      }
    },
    togglePasswordType() {
      this.showPassword = !this.showPassword;
      this.errorMessage = "";
    },
    toggleForgot() {
      this.forgot = !this.forgot;
    },
    submitForm() {
      if (this.forgot) {
        this.forgotPassword();
      } else {
        this.signIn();
      }
    },
  },
};
</script>

<template>
  <div class="d-flex align-center justify-center fill-height">
    <div class="d-flex justify-center full-width">
      <v-row>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          xl="4"
          lg="5"
          md="6"
          sm="10"
          class="d-flex justify-center"
        >
          <v-card class="elevation-4" max-width="550" width="100%">
            <v-card-text class="px-6 pb-0 pt-6">
              <v-form ref="form" lazy-validation>
                <v-text-field
                  v-model="model.email"
                  :rules="rules.email"
                  color="primary"
                  :label="$t('auth.fields.login')"
                  name="login"
                  prepend-inner-icon="mdi-account"
                  type="text"
                  @keypress.enter="submitForm()"
                />
                <v-expand-transition>
                  <div v-if="!forgot">
                    <v-text-field
                      id="password"
                      v-model="model.password"
                      :rules="rules.password"
                      :type="PasswordType"
                      append-icon="mdi-eye"
                      color="primary"
                      :label="$t('auth.fields.password')"
                      name="password"
                      prepend-inner-icon="mdi-lock"
                      @click:append="togglePasswordType"
                      @keypress.enter="submitForm()"
                    />
                  </div>
                </v-expand-transition>
                <v-checkbox
                  v-model="model.user_type"
                  true-value="doctor"
                  false-value="patient"
                >
                  <template v-slot:label>
                    <div>Sunt medic</div>
                  </template>
                </v-checkbox>
              </v-form>
              <div class="text-right">
                <a v-if="forgot" @click="toggleForgot">
                  {{ $t("auth.local_buttons.enter_in_account") }}
                </a>
                <!--                <a v-else @click="toggleForgot">-->
                <!--                  {{ $t("auth.local_buttons.forgot_password") }}-->
                <!--                </a>-->
              </div>
            </v-card-text>
            <v-card-actions class="px-6 pb-6 pt-3 d-block">
              <v-btn
                color="primary"
                dark
                depressed
                width="100%"
                @click="submitForm()"
              >
                {{ $t("auth.local_buttons.authorise") }}
              </v-btn>
              <v-expand-transition>
                <v-alert
                  v-if="errorMessage"
                  class="mt-4"
                  dense
                  outlined
                  type="error"
                >
                  {{ errorMessage }}
                </v-alert>
              </v-expand-transition>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.row {
  width: 100vw;
}
</style>
